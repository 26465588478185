import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',   //请求  /login 去对应路径中寻找
    name: '首页',
    component: () => import('../views/layout/index.vue'),

    redirect: '/home',

    // 写子路由：为了实现  对应页/首页  这个效果
    children: [
      // 因为绑定了路由，所以需要注意与menu中一致
      {
        path: '/home',
        name: '首页',
        component: () => import('../views/home/index.vue')
      },
      {
        path: '/user',
        name: '用户管理',
        component: () => import('../views/user/index.vue')
      },
      {
        path: '/video',
        name: '视频审核',
        component: () => import('../views/video/index.vue')
      },
      {
        path: '/images',
        name: '图片审核',
        component: () => import('../views/images/index.vue')
      },
      {
        path: '/manageArticles',
        name: '管理文章',
        component: () => import('../views/manageArticles/index.vue')
      },
      {
        path: '/publishArticles',
        name: '发布文章',
        component: () => import('../views/publishArticles/index.vue')
      },
      {
        path: '/push',
        name: '推送管理',
        component: () => import('../views/push/index.vue')
      },
      {
        path: '/modifyPassword',
        name: '修改密码',
        component: () => import('../views/modifyPassword/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router
