import router from '@/router';
import { createStore } from 'vuex';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    logout() {
      //安全退出
      window.sessionStorage.clear();
      window.localStorage.clear();
      router.replace('login');
    }

  },
  modules: {
  }
})
