import router from '@/router/index'
// to 到哪里    from 来自哪里  nex是否可以进入
router.beforeEach((to, from, next) => {
    let token = window.sessionStorage.getItem("token");
    console.log("token:" + token);
    console.log("to.path=" + to.path);

    const whiteList = ['/login']    //白名单

    if(token){  //token存在
        if(to.path == "/login"){    //如果是登录请求
            next("/");  //进入首页
        }else{
            next();  //放行
        }
    }else{
        if(whiteList.includes(to.path)){    //如果是在白名单中的请求
            next(); //无需登录直接放行
        }else{
            next("/login");     //否则跳转到登录页面 进行登录
        }
    }
})